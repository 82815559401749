import React from "react"
import { Link } from "gatsby"
import { Stack, Paper, Chip, Typography, Box} from "@mui/material"
import { styled } from "@mui/material/styles"
import moment from "moment"
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';

export const BlogList = ({ blogs }) => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
  }));
  return (
      <Stack spacing={2}>
        {blogs.map(({node}, index) => (
        <Item key={node.blogId}>
                <Stack direction={{xs:"column", sm:"row"}} spacing="1">
                    <Box sx={{ maxWidth: {xs:"100%", sm:"20%"}, marginRight: "1em", display: "flex", alignItems: "center" }}>
                        {node.thumbnail && (
                            <img width="1200" height="630" loading={index < 1 ? "eager" : "lazy"} src={node.thumbnail.url + "?auto=compress&fm=webp&w=350"} srcSet={node.thumbnail.url + "?auto=compress&fm=webp&w=550 600w, "+node.thumbnail.url+"?auto=compress&fm=webp&w=350, 5000w"}></img>
                        )}
                    </Box>
                    <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                        <Box>
                            <Box component="span" sx={{ marginRight: ".5em" }}>
                                {moment(node.date).format(`YYYY/MM/DD`)}
                            </Box>
                            <Typography variant="h1" sx={{ fontSize: "24pt", fontWeight: "bold" }}>
                                <Link to={`/blog/${node.blogId}/`}>{node.title}</Link>
                            </Typography>
                        </Box>
                        {node.description && (
                            <Box className="read-more">
                                {node.description} <Link to={`/blog/${node.blogId}/`}>...続きを読む</Link>
                            </Box>
                        )}
                        <Box>
                            {node.category && (<Chip label={node.category.name} sx={{ marginRight: ".5em" }} />)}
                            {node.tags.length > 0 && node.tags.map(t => (<Box key={t.name} component="span" sx={{ marginRight: ".5em" }}>
                                <LocalOfferRoundedIcon fontSize="inherit" />{t.name}
                            </Box>))}
                        </Box>
                    </Stack>
                </Stack>
            </Item>
        ))}
        </Stack>
    )
}